import { inject } from '@angular/core';
import { CanActivateChildFn, CanActivateFn, Router } from '@angular/router';
import { of, switchMap } from 'rxjs';
import { AuthService } from './auth.service';
import { ToastrService } from 'ngx-toastr';

export const AuthGuard: CanActivateFn | CanActivateChildFn = (route, state) => {
    const router: Router = inject(Router);
    const toastr: ToastrService = inject(ToastrService);

    return inject(AuthService).isLoggedIn.pipe(
        switchMap((authenticated) => {
            if (!authenticated) {
                if (state.url === '/shop/checkout') {
                    toastr.warning('Please log in to access the checkout page.', 'Login Required');
                    return of(router.parseUrl(`auth/login?redirectURL=${state.url}`));
                }

                const redirectURL = state.url === '/sign-out' ? '' : `redirectURL=${state.url}`;
                const urlTree = router.parseUrl(`auth/login?${redirectURL}`);
                return of(urlTree);
            }

            return of(true);
        }),
    );
};
