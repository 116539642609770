import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Product } from '../../../../shared/classes/product';
import { ProductService } from '../../../../shared/services/product.service';

@Component({
    selector: 'app-related-product',
    templateUrl: './related-product.component.html',
    styleUrls: ['./related-product.component.scss']
})
export class RelatedProductComponent implements OnChanges {

    @Input() type: string;
    @Input() id: number;

    public products: Product[] = [];

    constructor(public productService: ProductService) { }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.id && changes.id.currentValue) {
            this.productService.getRelatedProducts(this.id).subscribe((products: Product[]) => {
                this.products = products;
            });
        }
    }
}
