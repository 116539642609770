// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiUrl:'https://account.horizononlinelab.com/api/v1/',
//   apiUrl:'http://localhost:8000/api/v1/',

  stripe_token: 'pk_test_51Q0QZqRvhVllLYf1T8x3ZqOQP7fLZjgaq8WHyRWKvdY3DLsSFGU82Xxim5ibeGCLN2LL1w69N1UxPdKIEPe3zJAg00A78HUB4D',
  paypal_token: 'AYh6t5p0UDWxFhJly3JU8O9fq5FvWNR_3Sy0MNyaZd-HSQ47JQpag_LqrYow9bBcZOVknngTMvCiGeaQ',
  // apiUrl: 'http://127.0.0.1:8003/api/v1/',
  cloudFront: 'https://4smiles3.s3.eu-north-1.amazonaws.com/',
  auth: {
    grant_type: 'password',
    client_id: '9b7608a8-b4da-44a7-916f-1a3142c9e4fa',
    client_secret: 'eGIavaJCbHK8zkJtFV9dVd6WfmhKfdHSAcJw4qTy',
    scope: 'manage-account'
  },
  pusher: {
    key: '475366470398db0f26ec',
    cluster: 'ap2'
  },
  googleMapsApiKey: 'AIzaSyBif9bCI7AyENJGlmWNy48m3Ld1GNiiEto',
  // assets: 'http://127.0.0.1:8003/',
  assets: 'https://admin.4smile.com/public/',

  paypalClient: 'AYh6t5p0UDWxFhJly3JU8O9fq5FvWNR_3Sy0MNyaZd-HSQ47JQpag_LqrYow9bBcZOVknngTMvCiGeaQ',

  firebaseConfig: {
    apiKey: "AIzaSyCEEHrOJo0mpJf4DtZvXsclccUC-IV6TRg",
    authDomain: "smile-65875.firebaseapp.com",
    projectId: "smile-65875",
    storageBucket: "smile-65875.appspot.com",
    messagingSenderId: "791608991480",
    appId: "1:791608991480:web:30c46976282387006de50d",
    measurementId: "G-2LDD0J8481",
    vapidKey: "BNcEGkwg6Jo5jYQXtMxwMVinZ39ujD5tg5k2neRpA8rtXVTy6li7PaFIXg89SQSKT4M8IK0DWgkrel0JwrFcuNw"
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
