import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, BehaviorSubject, throwError } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    private apiUrl = environment.apiUrl;
    private tokenKey = 'authToken';
    private loggedIn = new BehaviorSubject<boolean>(this.hasToken());

    constructor(
        private http: HttpClient,
        private router: Router,
    ) { }

    private hasToken(): boolean {
        return !!localStorage.getItem(this.tokenKey);
    }

    get isLoggedIn(): Observable<boolean> {
        return this.loggedIn.asObservable();
    }

    setLoggedIn() {
       this.loggedIn.next(true);
    }

    signIn(credentials: FormData): Observable<any> {
        return this.http.post<{ token: string }>(`${this.apiUrl}auth/login`, credentials)
            .pipe(
                tap(response => {
                    localStorage.setItem(this.tokenKey, response.token);
                    // this.get(response.token);
                    console.log(response);

                    this.loggedIn.next(true);
                }),
                catchError(this.handleError)
            );
    }

    mergeCart(id) {
        return this.http.post<any>(`${this.apiUrl}cart/merge?guest_id=${id}` , {} );
    }

    get(token): Observable<any> {
        const httpHeaders = new HttpHeaders({
            // eslint-disable-next-line @typescript-eslint/naming-convention
            Authorization: `Bearer ${token}`,
        });
        return this.http.get<any>(`${this.apiUrl}customer/info`, {
            headers: httpHeaders,
        }).pipe(
            tap((user) => {
                localStorage.setItem('user', JSON.stringify(user));
            })
        );
    }


    // self(): Observable<any> {
    //     return this.http.get<any>(`${this.apiUrl}customer/info`).pipe(
    //        tap((response: any) => {
    //         localStorage.setItem('user', JSON.stringify(response));
    //        })
    //     );
    //  }

    // getUserByToken(): Observable<any> {

    //     const token =  localStorage.getItem(this.tokenKey);
    //     if (!token) {
    //         return of(undefined);
    //     }

    //     return this.http.get(`${this.apiUrl}auth/register`, token).pipe(
    //         switchMap((user: any) => {

    //             return user;
    //         })
    //     );
    // }

    register(user: any): Observable<any> {
        return this.http.post(`${this.apiUrl}auth/register`, user).pipe(catchError(this.handleError));
    }

    logout(): void {
        localStorage.removeItem(this.tokenKey);
        localStorage.removeItem('user');
        this.loggedIn.next(false);
        this.router.navigate(['/login']);
    }

    getToken(): string | null {
        return localStorage.getItem(this.tokenKey);
    }

    //   isTokenExpired(): boolean {
    //     const token = this.getToken();
    //     return token ? this.jwtHelper.isTokenExpired(token) : true;
    //   }

    private handleError(error: HttpErrorResponse) {
        return throwError(() => error);
    }
}
