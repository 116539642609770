<div class="product-detail w-100">
  <div class="row flex-column">
    <!-- Left Column Skeleton -->
    <div class="col-12">
      <!-- Skeleton for Rating -->
      <ngx-skeleton-loader
        [theme]="{ 'background-color': '#e8e8e8', 'border-radius': '5px', width: '100px', height: '20px', 'margin-bottom': '10px' }">
      </ngx-skeleton-loader>

      <!-- Skeleton for Product Name -->
      <h6>
        <ngx-skeleton-loader
          [theme]="{ 'background-color': '#e8e8e8', width: '180px', 'border-radius': '10px', height: '20px', 'margin-bottom': '10px' }">
        </ngx-skeleton-loader>
      </h6>

      <!-- Skeleton for Product Details -->
      <p>
        <ngx-skeleton-loader
          [theme]="{ 'background-color': '#e8e8e8', 'border-radius': '5px', width: '100%', height: '15px', 'margin-bottom': '6px' }">
        </ngx-skeleton-loader>
        <ngx-skeleton-loader
          [theme]="{ 'background-color': '#e8e8e8', 'border-radius': '5px', width: '90%', height: '15px', 'margin-bottom': '6px' }">
        </ngx-skeleton-loader>
        <ngx-skeleton-loader
          [theme]="{ 'background-color': '#e8e8e8', 'border-radius': '5px', width: '85%', height: '15px' }">
        </ngx-skeleton-loader>
      </p>
    </div>

    <!-- Right Column Skeleton -->
    <div class="col-12">
      <!-- Skeleton for Price -->
      <h4>
        <div class="fs-4">
          <ngx-skeleton-loader
            [theme]="{ 'background-color': '#e8e8e8', width: '120px', 'border-radius': '5px', height: '25px' }">
          </ngx-skeleton-loader>
          <!-- <del>
            <ngx-skeleton-loader
              [theme]="{ 'background-color': '#e8e8e8', width: '60px', 'border-radius': '5px', height: '20px', 'margin-left': '10px' }">
            </ngx-skeleton-loader>
          </del> -->
        </div>
      </h4>

      <!-- Skeleton for Add to Cart Button -->
      <div class="mt-2">
        <ngx-skeleton-loader
            [theme]="{ 'background-color': '#e8e8e8', width: '100%', 'border-radius': '50px', height: '42px' }">
          </ngx-skeleton-loader>
      </div>
    </div>
  </div>
</div>
