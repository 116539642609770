<app-breadcrumb [title]="product?.name" [breadcrumb]="'Product'"></app-breadcrumb>
<!-- section start -->
<section class="section-b-space">
  <div class="collection-wrapper">
    <div class="container">
      <div class="row">
        <div class="col-sm-3">
          <div class="collection-filter" [class.openFilterbar]="mobileSidebar">
            <div class="collection-filter-block">
                <div class="collection-mobile-back" (click)="toggleMobileSidebar()">
                  <span class="filter-back">
                    <i class="fa fa-angle-left" aria-hidden="true"></i> back
                  </span>
                </div>
                <app-categories></app-categories>
            </div>
            <!-- <app-product-box-vertical-slider
                [title]="'New product'"
                [type]="product?.type">
            </app-product-box-vertical-slider> -->
          </div>
        </div>
        <div class="col-lg-9 col-sm-12 col-xs-12">
          <div class="container-fluid p-0">
            <div class="row">
              <div class="col-xl-12">
                <div class="filter-main-btn mb-2">
                  <span class="filter-btn" (click)="toggleMobileSidebar()">
                    <i class="fa fa-bars" aria-hidden="true"></i> sidebar
                  </span>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6">
                <div class="col-12 p-0" *ngIf="product?.images_full_url?.length > 0">
                    <div class="slider-nav">
                        <img [defaultImage]="'assets/images/product/placeholder.jpg'"
                            [lazyLoad]="product?.images_full_url[0].path"
                            alt="Product Image" class="img-fluid">
                    </div>
                </div>
              </div>
              <div class="col-lg-6 rtl-text">
                <div class="product-right">
                    <ng-container *ngIf="loading">
                        <ngx-skeleton-loader
                            [theme]="{ 'background-color': '#e8e8e8', width: '100%', 'border-radius': '5px', height: '25px' }">
                        </ngx-skeleton-loader>
                        <ngx-skeleton-loader
                            [theme]="{ 'background-color': '#e8e8e8', width: '25%', 'border-radius': '5px', height: '25px' }">
                        </ngx-skeleton-loader>
                        <ngx-skeleton-loader
                            [theme]="{ 'background-color': '#e8e8e8', width: '100%', 'border-radius': '5px', height: '2px' }">
                        </ngx-skeleton-loader>
                        <ngx-skeleton-loader
                            [theme]="{ 'background-color': '#e8e8e8', width: '20%', 'border-radius': '5px', height: '25px' }">
                        </ngx-skeleton-loader>
                        <h6>
                            <ngx-skeleton-loader
                                [theme]="{ 'background-color': '#e8e8e8', width: '40%', 'border-radius': '5px', height: '35px' }">
                            </ngx-skeleton-loader>
                        </h6>
                        <ngx-skeleton-loader
                            [theme]="{ 'background-color': '#e8e8e8', width: '30%', 'border-radius': '5px', height: '30px' }">
                        </ngx-skeleton-loader>
                        <ngx-skeleton-loader
                            [theme]="{ 'background-color': '#e8e8e8', width: '25%', 'border-radius': '5px', height: '30px',
                            'margin-left': '1rem' }">
                        </ngx-skeleton-loader>
                        <ngx-skeleton-loader
                            [theme]="{ 'background-color': '#e8e8e8', width: '100%', 'border-radius': '5px', height: '2px' }">
                        </ngx-skeleton-loader>
                        <ngx-skeleton-loader
                            [theme]="{ 'background-color': '#e8e8e8', width: '25%', 'border-radius': '5px', height: '25px'}">
                        </ngx-skeleton-loader>
                        <ngx-skeleton-loader
                            [theme]="{ 'background-color': '#e8e8e8', width: '100%', 'border-radius': '5px', height: '25px'}">
                        </ngx-skeleton-loader>
                        <ngx-skeleton-loader
                            [theme]="{ 'background-color': '#e8e8e8', width: '60%', 'border-radius': '5px', height: '25px'}">
                        </ngx-skeleton-loader>
                        <ngx-skeleton-loader
                            [theme]="{ 'background-color': '#e8e8e8', width: '100%', 'border-radius': '5px', height: '2px' }">
                        </ngx-skeleton-loader>
                    </ng-container>

                    <ng-container *ngIf="!loading">
                      <h2>{{ product?.name }}</h2>
                      <h4>
                        <div class="fs-4 ">
                            {{ product?.unit_price * productService.Currency?.price | discount:product | currency:productService.Currency?.currency:'symbol' }}
                            <del *ngIf="product?.discount">
                              <span class="money">
                                  {{ product?.unit_price * productService.Currency?.price | currency:productService.Currency?.currency:'symbol' }}
                              </span>
                          </del>
                        </div>
                      </h4>
                      <app-stock-inventory [stock]="product?.stock"></app-stock-inventory>
                      <div class="product-description border-product">
                        <h5 class="avalibility" *ngIf="counter <= product.stock"><span>In Stock</span></h5>
                        <h5 class="avalibility" *ngIf="counter > product.stock"><span>Out of Stock</span></h5>
                        <h6 class="product-title">quantity</h6>
                        <div class="qty-box">
                          <div class="input-group">
                            <span class="input-group-prepend">
                              <button type="button" class="btn quantity-left-minus" data-type="minus" (click)="decrement()">
                                <i class="ti-angle-left"></i>
                              </button>
                            </span>
                            <input type="text" name="quantity" class="form-control input-number" [value]="counter" disabled/>
                            <span class="input-group-prepend">
                              <button type="button" class="btn quantity-right-plus" data-type="plus" (click)="increment()">
                                <i class="ti-angle-right"></i>
                              </button>
                            </span>
                          </div>
                        </div>
                      </div>
                      <div class="product-buttons">
                        <a href="javascrip:void(0)" class="btn btn-solid" [class.disabled]="counter > product.stock" (click)="addToCart(product)">add to cart</a>
                        <a href="javascrip:void(0)" class="btn btn-solid" [class.disabled]="counter > product.stock" (click)="buyNow(product)">buy now</a>
                      </div>
                      <div class="border-product">
                        <h6 class="product-title">product details</h6>
                        <p>{{ product?.meta_description?.substring(0, 100) + '...' }}</p>
                      </div>
                      <div class="border-product"></div>
                    </ng-container>
                </div>
              </div>
            </div>
          </div>
          <section class="tab-product m-0">
            <div class="row">
              <div class="col-sm-12 col-lg-12">
                <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs">
                    <li [ngbNavItem]="1">
                      <a ngbNavLink>
                        <ng-container *ngIf="loading; else descriptionTab">
                          <ngx-skeleton-loader
                            [theme]="{ 'background-color': '#e8e8e8', width: '100%', 'border-radius': '5px', height: '35px' }">
                          </ngx-skeleton-loader>
                        </ng-container>
                        <ng-template #descriptionTab>Description</ng-template>
                      </a>

                      <ng-template ngbNavContent>
                        <ng-container *ngIf="loading; else descriptionContent">
                          <ngx-skeleton-loader
                            [theme]="{ 'background-color': '#e8e8e8', width: '100%', 'border-radius': '5px', height: '25px' }">
                          </ngx-skeleton-loader>
                          <ngx-skeleton-loader
                            [theme]="{ 'background-color': '#e8e8e8', width: '100%', 'border-radius': '5px', height: '25px' }">
                          </ngx-skeleton-loader>
                          <ngx-skeleton-loader
                            [theme]="{ 'background-color': '#e8e8e8', width: '100%', 'border-radius': '5px', height: '25px' }">
                          </ngx-skeleton-loader>
                          <ngx-skeleton-loader
                            [theme]="{ 'background-color': '#e8e8e8', width: '60%', 'border-radius': '5px', height: '25px' }">
                          </ngx-skeleton-loader>
                        </ng-container>
                        <ng-template #descriptionContent>
                          <p [innerHTML]="product?.details"></p>
                        </ng-template>
                      </ng-template>
                    </li>
                  </ul>
                <div [ngbNavOutlet]="nav" class="mt-2"></div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  </div>
  <app-related-product [type]="'product'" [id]="product.id"></app-related-product>
</section>
<!-- Section ends -->
