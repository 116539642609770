<div *ngIf="!loader">
    <div class="product-detail w-100 pt-2 pb-3 ">
      <div class="row">
        <div class="col-lg-12 text-center">
          <a [routerLink]="productRouteUrl">
            <h4 class="fw-bold mb-4 mt-3">{{ product?.name | titlecase }}</h4>
          </a>
        </div>
  
        <div class="col-12 ">
          <div class="btn btn-solid btn-block mt-2 view-btn">
            <div [routerLink]="productRouteUrl">
              <i class="ti-eye"></i> View
            </div>
    
          </div>
        </div>
      </div>
    </div>
  </div>
  
  <app-skeleton-product-box *ngIf="loader"></app-skeleton-product-box>
  <!-- <app-quick-view #quickView [product]="product" [currency]="currency"></app-quick-view> -->
  <app-cart-modal #cartModal [product]="product" [currency]="currency" *ngIf="cartModal"></app-cart-modal>
  