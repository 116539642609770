<div *ngIf="!loader">
  <div class="product-detail w-100 pt-2 pb-3 ">
    <div class="row">
      <div class="col-lg-12">
        <bar-rating [rate]="5" [readOnly]="true"></bar-rating>
        <a [routerLink]="productRouteUrl">
          <h4 class="fw-bold mb-4 mt-3">{{ product?.name | titlecase }}</h4>
        </a>
      </div>

      <div class="col-12 ">
        <p [innerHTML]="product.details | slice:0:350" class="product-description"></p>

        <h4>
          <div class="fs-4 ">
            {{ product?.unit_price * currency?.price | discount:product | currency:currency?.currency:'symbol' }}
            <del *ngIf="product?.discount"><span class="money"> {{ product?.unit_price * currency?.price |
                currency:currency?.currency:'symbol' }}</span></del>
          </div>
        </h4>
        <div class="btn btn-solid btn-block mt-2 view-btn">
          <div [routerLink]="productRouteUrl">
            <i class="ti-eye"></i> View
          </div>
         <!-- <a href="javascript:void(0)" class="text-light" title="Add to cart" (click)="CartModal.openModal(product)" -->
          <!-- <div class="" title="Add to cart" (click)="addToCart(product)"
            *ngIf="cartModal">
            <i class="ti-shopping-cart"></i> add to cart
          </div> -->
        </div>
      </div>
    </div>
  </div>
</div>

<app-skeleton-product-box *ngIf="loader"></app-skeleton-product-box>
<!-- <app-quick-view #quickView [product]="product" [currency]="currency"></app-quick-view> -->
<app-cart-modal #cartModal [product]="product" [currency]="currency" *ngIf="cartModal"></app-cart-modal>
