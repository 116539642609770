<!-- product section start -->
<section class="section-b-space" *ngIf="products && products.length > 0">
    <div class="container">
        <div class="row">
            <div class="col-12 product-related">
                <h2>Related Products</h2>
            </div>
        </div>
        <div class="row search-product">
            <div class="col-xl-3 col-md-4 col-sm-6" *ngFor="let product of products | slice:0:4">
                <div class="product-box w-100">
                   <app-product-box-one
	                  [product]="product"
	                  [type]="'product'"
	                  [currency]="productService?.Currency"
	                  [cartModal]="true"
                      [loader]="true">
	                </app-product-box-one>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- product section end -->
