import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { map, startWith, delay, tap, switchMap, catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { Category, Product } from '../classes/product';
import { ApiService } from 'src/app/core/service/api.service';

const state = {
    products: JSON.parse(localStorage['products'] || '[]'),
    wishlist: JSON.parse(localStorage['wishlistItems'] || '[]'),
    compare: JSON.parse(localStorage['compareItems'] || '[]'),
    cart: JSON.parse(localStorage['cartItems'] || '[]')
}

@Injectable({
    providedIn: 'root'
})
export class ProductService {

    public Currency = { name: 'Dollar', currency: 'USD', price: 1 } // Default Currency
    public OpenCart: boolean = false;
    public Products

    private _product: BehaviorSubject<Product> = new BehaviorSubject(null);
    private _relatedProducts: BehaviorSubject<Product[]> = new BehaviorSubject(null);
    private _categories: BehaviorSubject<any[] | null> = new BehaviorSubject(null);
    private _category: BehaviorSubject<any | null> = new BehaviorSubject(null);
    private _subcategory: BehaviorSubject<any | null> = new BehaviorSubject(null);
    private _cart = new BehaviorSubject<Product[]>([]);
    cart$ = this._cart.asObservable();

    constructor(
        private http: HttpClient,
        private toastrService: ToastrService,
        private _apiService: ApiService
    ) { }

    get product$(): Observable<Product> {
        return this._product.asObservable();
    }

    get relatedProducts$(): Observable<Product[]> {
        return this._relatedProducts.asObservable();
    }

    get categories$(): Observable<any[]> {
        return this._categories.asObservable();
    }

    get category$(): Observable<any[]> {
        return this._category.asObservable();
    }

    get subcategory$(): Observable<any[]> {
        return this._subcategory.asObservable();
    }

    public getProductDetails(slug: string): Observable<Product> {
        return this._apiService.get<Product>(`products/details/${slug}`).pipe(
            tap(product => {
                this._product.next(product);
            })
        );
    }

    public getRelatedProducts(id: number): Observable<Product[]> {
        return this._apiService.get<Product[]>(`products/related-products/${id}`).pipe(
            tap(relatedProducts => {
                this._relatedProducts.next(relatedProducts);
            })
        );
    }

    // Product
    private get products(): Observable<Product[]> {
        this.Products = this.http.get<Product[]>('assets/data/products.json').pipe(map(data => data));
        this.Products.subscribe(next => { localStorage['products'] = JSON.stringify(next) });
        return this.Products = this.Products.pipe(startWith(JSON.parse(localStorage['products'] || '[]')));
    }

    // Get Products
    public get getProducts(): Observable<Product[]> {
        return this.products;
    }

    // Get Products By Slug
    public getProductBySlug(slug: string): Observable<Product> {
        return this.products.pipe(map(items => {
            return items.find((item: any) => {
                return item.title.replace(' ', '-') === slug;
            });
        }));
    }

    getCategories(): Observable<any[]> {
        return this._apiService.get<any[]>('categories').pipe(
            tap((response: any) => {
                this._categories.next(response);
            }),
        );
    }

    getCategoryById(id: string): Observable<any> {
        return this._apiService.get<any>(`categories/${id}`).pipe(
            map((category) => {
                this._category.next(category);

                return category;
            }),
            switchMap((category) => {
                if (!category) {
                    return throwError(() => 'Could not found category with id of ' + id + '!');
                }

                return of(category);
            }),
        );
    }

    getSubCategoryById(id: string, subId: string): Observable<any> {
        return this._apiService.get<any>(`categories/${id}/${subId}`).pipe(
            map((subcategory) => {
                this._subcategory.next(subcategory);

                return subcategory;
            }),
            switchMap((subcategory) => {
                if (!subcategory) {
                    return throwError(() => 'Could not found subcategory with id of ' + id + '!');
                }

                return of(subcategory);
            }),
        );
    }


    // Get category by slug
    public getCategoryBySlug(slug: string): Observable<Category> {
        return this._categories.pipe(
            map(categories => categories.find(category => category.slug === slug))
        );
    }


    /*
      ---------------------------------------------
      ---------------  Wish List  -----------------
      ---------------------------------------------
    */

    // Get Wishlist Items
    public get wishlistItems(): Observable<Product[]> {
        const itemsStream = new Observable(observer => {
            observer.next(state.wishlist);
            observer.complete();
        });
        return <Observable<Product[]>>itemsStream;
    }

    // Add to Wishlist
    public addToWishlist(product): any {
        const wishlistItem = state.wishlist.find(item => item.id === product.id)
        if (!wishlistItem) {
            state.wishlist.push({
                ...product
            })
        }
        this.toastrService.success('Product has been added in wishlist.');
        localStorage.setItem("wishlistItems", JSON.stringify(state.wishlist));
        return true
    }

    // Remove Wishlist items
    public removeWishlistItem(product: Product): any {
        const index = state.wishlist.indexOf(product);
        state.wishlist.splice(index, 1);
        localStorage.setItem("wishlistItems", JSON.stringify(state.wishlist));
        return true
    }

    /*
      ---------------------------------------------
      -------------  Compare Product  -------------
      ---------------------------------------------
    */

    // Get Compare Items
    public get compareItems(): Observable<Product[]> {
        const itemsStream = new Observable(observer => {
            observer.next(state.compare);
            observer.complete();
        });
        return <Observable<Product[]>>itemsStream;
    }

    // Get Cart Items from the API


    // Add to Compare
    public addToCompare(product): any {
        const compareItem = state.compare.find(item => item.id === product.id)
        if (!compareItem) {
            state.compare.push({
                ...product
            })
        }
        this.toastrService.success('Product has been added in compare.');
        localStorage.setItem("compareItems", JSON.stringify(state.compare));
        return true
    }

    // Remove Compare items
    public removeCompareItem(product: Product): any {
        const index = state.compare.indexOf(product);
        state.compare.splice(index, 1);
        localStorage.setItem("compareItems", JSON.stringify(state.compare));
        return true
    }

    /*
      ---------------------------------------------
      -----------------  Cart  --------------------
      ---------------------------------------------
    */

    // Get Cart Items
    public get cartItems(): Observable<Product[]> {
        // return this._apiService.get<Product[]>('cart');
        const itemsStream = new Observable(observer => {
            observer.next(state.cart);
            observer.complete();
        });
        return <Observable<Product[]>>itemsStream;
    }


    // public get cartItems(): Observable<any[]> {
    //     const guestId = localStorage.getItem('guest_id') ?? null;

    //     return this._apiService.get<any[]>('cart', {
    //         guest_id: guestId ? Number(guestId) : null,}
    //     ).pipe(
    //         tap((items: any[]) => {
    //             // Update the local state with items from the API
    //             state.cart = items;
    //             localStorage.setItem("cartItems", JSON.stringify(state.cart));
    //             this._cart.next(state.cart); // Notify subscribers about the updated cart
    //         }),
    //         catchError(error => {
    //             console.error("Error fetching cart items:", error);
    //             return of([]); // Return an empty array on error
    //         })
    //     );
    // }

    get cartStatus$(): Observable<object> {
        return this._cart.asObservable();
    }

    public addToCart(product: any): boolean {
        const { id, quantity = 1 } = product;
        console.log(product);

        const cartItem = state.cart.find(item => item.id === id);
        const updatedQuantity = cartItem ? cartItem.quantity + quantity : quantity;
        const stockAvailable = this.calculateStockCounts(cartItem || product, updatedQuantity);

        if (!stockAvailable) {
            return false;
        }

        if (cartItem) {
            cartItem.quantity = updatedQuantity;
        } else {
            state.cart.push({
                ...product,
                quantity: updatedQuantity
            });
        }

        const user = localStorage.getItem('user');
        const guestId = localStorage.getItem('guest_id') ?? null;
        const customerId = user ? user['id'] : null;

        // Send the add to cart request to the backend
        this._apiService.post(`cart/add`, {
            id,
            quantity: updatedQuantity,
            guest_id: guestId ? Number(guestId) : null,
            customer_id: customerId ? Number(customerId) : null
        }).pipe(
            tap((response: any) => {
                if (response && response.in_cart_key) {
                    const updatedCart = state.cart.map(item =>
                        item.id === id ? { ...item, in_cart_key: response.in_cart_key } : item
                    );
                    console.log(response);

                    state.cart = updatedCart;
                    localStorage.setItem("cartItems", JSON.stringify(state.cart));
                    this._cart.next(updatedCart);
                }
            })
        ).subscribe({
            error: (error) => console.error("Error adding to cart:", error)
        });

        return true;
    }


    // Update Cart Quantity
    // Update Cart Quantity using the API
    // Update Cart Quantity in Angular
    public updateCartQuantity(product: Product, quantity: number): boolean {
        const index = state.cart.findIndex(item => item.id === product.id);

        if (index > -1) {
            const updatedQuantity = state.cart[index].quantity + quantity;
            const stockAvailable = this.calculateStockCounts(state.cart[index], quantity);

            if (updatedQuantity > 0 && stockAvailable) {
                // Update the local cart state
                state.cart[index].quantity = updatedQuantity;
                localStorage.setItem("cartItems", JSON.stringify(state.cart));
                this._cart.next(state.cart);

                const guestId = localStorage.getItem('guest_id') ?? null;

                // Send the update request to the backend
                this._apiService.put(`cart/update`, {
                    key: product.in_cart_key, // Assuming you use product ID as the key
                    quantity: updatedQuantity,
                    guest_id: guestId ? Number(guestId) : null,
                }).subscribe({
                    next: (response: any) => {
                        console.log("Cart updated on the server:", response);
                        // Optionally, update the local cart with the response if needed
                    },
                    error: (error) => {
                        console.error("Error updating cart on the server:", error);
                        // Optionally, roll back the local state if the update fails
                    }
                });

                return true;
            }
        }
        return false;
    }


    // Calculate Stock Counts
    public calculateStockCounts(product, quantity) {
        const qty = product.quantity + quantity
        const stock = product.stock
        if (stock < qty || stock == 0) {
            this.toastrService.error('You can not add more items than available. In stock ' + stock + ' items.');
            return false
        }
        return true
    }

    // Remove Cart items
    // Remove Cart Item using the API
    public removeCartItem(product: Product): boolean {
        const index = state.cart.findIndex(item => item.id === product.id);

        if (index > -1) {
            // Remove the item from local cart state
            state.cart.splice(index, 1);
            localStorage.setItem("cartItems", JSON.stringify(state.cart));
            this._cart.next(state.cart);

            const user = localStorage.getItem('user');
            const guestId = localStorage.getItem('guest_id') ?? null;
            const customerId = user ? user['id'] : null;

            // Send the add to cart request to the backend
            // Send the remove request to the backend
            this._apiService.post(`cart/remove`, {
                key: product.in_cart_key,
                guest_id: guestId ? Number(guestId) : null,
                customer_id: customerId ? Number(customerId) : null
            }).subscribe({
                next: (response: any) => {
                    console.log("Item removed from server cart:", response);
                },
                error: (error) => {
                    console.error("Error removing item from server cart:", error);
                }
            });

            return true;
        }
        return false;
    }

    public removeCarts(): void {
        // Clear the cart data in local storage
        localStorage.setItem("cartItems", JSON.stringify([]));

        // Clear the cart in the state and BehaviorSubject
        state.cart = [];
        this._cart.next(state.cart);
    }


    // public removeCartItem(product: Product): any {
    //     const index = state.cart.indexOf(product);
    //     state.cart.splice(index, 1);
    //     localStorage.setItem("cartItems", JSON.stringify(state.cart));
    //     return true
    // }

    // Total amount
    public cartTotalAmount(): Observable<number> {
        return this.cartItems.pipe(map((product: Product[]) => {
            return product.reduce((prev, curr: Product) => {

                let unit_price = curr.unit_price;
                if (curr.discount) {
                    unit_price = curr.unit_price - (curr.unit_price * curr.discount / 100)
                }
                return (prev + unit_price * curr.quantity) * this.Currency.price;
            }, 0);
        }));
    }

    /*
      ---------------------------------------------
      ------------  Filter Product  ---------------
      ---------------------------------------------
    */

    // Get Product Filter
    public filterProducts(filter: any): Observable<Product[]> {
        return this.products.pipe(map(product =>
            product.filter((item: Product) => {
                if (!filter.length) return true
                const Tags = filter.some((prev) => { // Match Tags
                    if (item.tags) {
                        if (item.tags.includes(prev)) {
                            return prev
                        }
                    }
                })
                return Tags
            })
        ));
    }

    // Sorting Filter
    public sortProducts(products: Product[], payload: string): any {

        if (payload === 'ascending') {
            return products.sort((a, b) => {
                if (a.id < b.id) {
                    return -1;
                } else if (a.id > b.id) {
                    return 1;
                }
                return 0;
            })
        } else if (payload === 'a-z') {
            return products.sort((a, b) => {
                if (a.title < b.title) {
                    return -1;
                } else if (a.title > b.title) {
                    return 1;
                }
                return 0;
            })
        } else if (payload === 'z-a') {
            return products.sort((a, b) => {
                if (a.title > b.title) {
                    return -1;
                } else if (a.title < b.title) {
                    return 1;
                }
                return 0;
            })
        } else if (payload === 'low') {
            return products.sort((a, b) => {
                if (a.price < b.price) {
                    return -1;
                } else if (a.price > b.price) {
                    return 1;
                }
                return 0;
            })
        } else if (payload === 'high') {
            return products.sort((a, b) => {
                if (a.price > b.price) {
                    return -1;
                } else if (a.price < b.price) {
                    return 1;
                }
                return 0;
            })
        }
    }

    /*
      ---------------------------------------------
      ------------- Product Pagination  -----------
      ---------------------------------------------
    */
    public getPager(totalItems: number, currentPage: number = 1, pageSize: number = 16) {
        // calculate total pages
        let totalPages = Math.ceil(totalItems / pageSize);

        // Paginate Range
        let paginateRange = 3;

        // ensure current page isn't out of range
        if (currentPage < 1) {
            currentPage = 1;
        } else if (currentPage > totalPages) {
            currentPage = totalPages;
        }

        let startPage: number, endPage: number;
        if (totalPages <= 5) {
            startPage = 1;
            endPage = totalPages;
        } else if (currentPage < paginateRange - 1) {
            startPage = 1;
            endPage = startPage + paginateRange - 1;
        } else {
            startPage = currentPage - 1;
            endPage = currentPage + 1;
        }

        // calculate start and end item indexes
        let startIndex = (currentPage - 1) * pageSize;
        let endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

        // create an array of pages to ng-repeat in the pager control
        let pages = Array.from(Array((endPage + 1) - startPage).keys()).map(i => startPage + i);

        // return object with all pager properties required by the view
        return {
            totalItems: totalItems,
            currentPage: currentPage,
            pageSize: pageSize,
            totalPages: totalPages,
            startPage: startPage,
            endPage: endPage,
            startIndex: startIndex,
            endIndex: endIndex,
            pages: pages
        };
    }

}
